import "core-js/stable";
import "regenerator-runtime/runtime";
import Headroom from "headroom.js";
import TweenMax from "gsap";
import throttle from "lodash/throttle";
import { createApp } from "vue";

// App main
const navi = async () => {
    // Create our vue instance
    const mainNav = createApp({
        compilerOptions: {
            delimiters: ["${", "}"],
        },
        components: {},
        data: () => ({
            menuOpen: false,
            subnavOpen: false,
            lastSubnav: null,
            mobile: false,
        }),
        props: {
            offset: {
                type: Number,
                default: 0,
            },
            tolerance: {
                type: [Object, Number],
                default: 5,
            },
            scroller: String,
            initial: {
                type: String,
                default: "headroom",
            },
            pinned: {
                type: String,
                default: "headroom--pinned",
            },
            unpinned: {
                type: String,
                default: "headroom--unpinned",
            },
            top: {
                type: String,
                default: "headroom--top",
            },
            notTop: {
                type: String,
                default: "headroom--not-top",
            },
            bottom: {
                type: String,
                default: "headroom--bottom",
            },
            notBottom: {
                type: String,
                default: "headroom--not-bottom",
            },
        },
        computed: {
            navClass: function () {
                return {
                    header__nav: true,
                    "subnav-open": this.subnavOpen,

                    //active: this.isActive && !this.error,
                    //'text-danger': this.error && this.error.type === 'fatal'
                };
            },

            showFooterLinksInHeader: function () {
                return this.isMobile();
            },
        },
        methods: {
            isMobile: function () {
                if (window.matchMedia("(min-width: 1200px)").matches) {
                    return false;
                }

                return true;
            },
            onMouseEnter: function (evt) {
                console.log("OnMouseEnter : " + evt.currentTarget + " AND isMobile : " + this.isMobile());

                var p = evt.currentTarget;
                if (!this.isMobile()) {
                    if (p && p.querySelector(".subnav") != null) {
                        this.subnavOpen = true;
                        p.classList.add("is-hovered");
                    }
                }
            },
            onMouseLeave: function (evt) {
                console.log("OnMouseLeave : " + evt.currentTarget);
                var p = evt.currentTarget;

                if (!this.isMobile()) {
                    if (p && p.querySelector(".subnav") != null) {
                        p.classList.remove("is-hovered");
                        this.subnavOpen = false;
                    }
                }
            },

            toggleSubnav: function (evt) {
                console.log("Toggle Subnav : " + evt.currentTarget.getAttribute("data-subnav"));

                let b = evt.currentTarget;
                let p = b.parentNode;
                let subId = evt.currentTarget.getAttribute("data-subnav");
                let sub = document.getElementById(subId);

                if (this.isMobile()) {
                    //p.classList.toggle("is-expanded");
                    if (p.className.indexOf("is-expanded") != -1) {
                        TweenMax.to(p, 0.5, {
                            className: "-=is-expanded",
                            ease: Quad.easeOut,
                        });
                    } else {
                        TweenMax.to(p, 0.5, {
                            className: "+=is-expanded",
                            ease: Quad.easeOut,
                        });
                    }

                    b.classList.toggle("is-active");
                }
            },

            toggleMenu: function () {
                this.menuOpen = !this.menuOpen;
                console.log("Nav Open? " + (this.menuOpen ? "YES!" : "NOPE."));

                let body = document.getElementsByTagName("body")[0];

                if (this.isMobile()) {
                    if (this.menuOpen) {
                        body.classList.add("menu-opened");
                    } else {
                        body.classList.remove("menu-opened");
                    }
                }
            },

            throttleResize: throttle(function () {
                let body = document.getElementsByTagName("body")[0];
                let links = document.getElementsByClassName("header__link");
                body.classList.remove("menu-opened");

                this.menuOpen = false;
                this.subnavOpen = false;
                this.lastSubnav = null;
                this.mobile = this.isMobile();

                for (var i = 0; i < links.length; i++) {
                    let l = links[i];
                    let e = l.getElementsByClassName("expander")[0];

                    links[i].classList.remove("is-expanded");

                    if (e) {
                        e.classList.remove("is-active");
                    }
                }
            }, 100),

            init: function () {
                this.mobile = this.isMobile();
                window.addEventListener("resize", this.throttleResize);
            },

            initHeadroom: function () {
                let _this = this;
                let el = document.getElementById("main-nav");
                let scroller = window;
                let headroom;
               
                if (this.scroller && this.scroller.length > 0) {
                    scroller = document.querySelector(this.scroller);
                }

                headroom = new Headroom(el, {
                    offset: this.offset,
                    tolerance: this.tolerance,
                    scroller: scroller,
                    classes: {
                        initial: this.initial,
                        pinned: this.pinned,
                        unpinned: this.unpinned,
                        top: this.top,
                        notTop: this.notTop,
                        bottom: this.bottom,
                        notBottom: this.notBottom,
                    },
                    onPin() {
                        _this.$emit("onPin", el);
                    },
                    onUnpin() {
                        _this.$emit("onUnpin", el);
                    },
                    onTop() {
                        _this.$emit("onTop", el);
                        console.log("At TOP");
                    },
                    onNotTop() {
                        _this.$emit("onNotTop", el);
                    },
                    onBottom() {
                        _this.$emit("onBottom", el);
                    },
                    onNotBottom() {
                        _this.$emit("onNotBottom", el);
                    },
                });

                headroom.init();
                headroom.update();
            },
        },
        mounted() {
            console.log("Main Nav VUE is Ready, ok");
            console.log("refs log",this.$refs);
            this.$nextTick(function () {
                this.initHeadroom();
                this.init();
            });
        },
    });

    const root = mainNav.mount("#main-nav");
    return root
};
// Execute async function
navi()
    .then((root) => {
        console.log("Vue Nav Component Mounted");
    })
    .catch((error) => {
        console.error("Error Vue Nav Component", error);
    });

export default navi;
